<template>
	<div>
		<div class="intro_logo" >
			<h2 class="page_tit">{{  text_card_div_name }}</h2>
		</div>
		<div class="random_wrap">
			<div class="container">
				<div class="row">
					<div
						ref="main_card_wrap"
						class="main_card_wrap mt-20 card-box"
					>
						<div
							v-for="(card, index) in item_info.cartl_nft_purchase_list"
							:key="'card_' + index"
							class="main_card main_card_type4 mb-20"
						>
							<div class="cover atvImg" :class="{type2: $route.params.nft_card_div_code == 'NF00100007'}">
								<div
									class="atvImg-layer"
									:data-img="card.cartl_nft_img_url"
								></div>
								<!-- <div class="atvImg-layer" data-img="/assets/image/union_madam.png"></div> -->
								<!-- <div class="atvImg-layer" data-img="/assets/image/union_accountant.png"></div> -->
								<!-- <div class="atvImg-layer" data-img="/assets/image/union_consigliere.png"></div> -->
							</div>
						</div>
					</div>

					<div
						class="lb-wrap"
						:class="lb"
						@click="setLb"
					>
						<div class="lb-trans">
							<div class="lb-bg"></div>
						</div>
						<div
							class="lb-bottom-box"
						>
							<div class="drops_buy mt-40">
								<span class="count">
									<b>BUY</b>
									<strong class="col_red"> {{ text_unit }} {{ text_coin_quantity }} </strong>
								</span>
							</div>
							<div class="drops_area mt-20">
								<p class="txt_desc" v-html="message_01">
								</p>
								<div class="btn_area mt-20">
									<button class="btn_l btn_fill_blue" @click="$router.push({name: 'drops'})">{{  $language.common.ok }}</button>
								</div>
								<div class="link mt-20">
									<a @click="$router.push({ name: 'nft'})">{{  $language.nft.nft_list }} <i class="icon icon-arrow-right"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia0432'
	, props: ['user', 'item_result', 'item_card']
	, data: function () {
		return {
			lb: 'down'

		}
	}
	, computed: {
		item_info: function(){

			let t = this.item_result

			if(t){

				if (!t?.cartl_nft_purchase_list) {
					let d = []
					d.push({
						cartl_nft_img_url: t?.sl_img_url
					})
					this.$set(t, 'cartl_nft_purchase_list', d)
				}
			}else{
				t = {

				}
			}

			return t
		}
		, text_card_div_name: function(){
			let t = ''
			if(this.item_info.nft_card_div_name ){
				t = this.item_info.nft_card_div_name
			}else{
				t = 'MAFIA CARD'
			}
			return t
		}
		, text_coin_quantity: function(){
			let t = this.item_info.coin_token_quantity ? this.item_info.coin_token_quantity : this.item_info.tot_purchase_coin_token_quantity ? this.item_info.tot_purchase_coin_token_quantity: 0

			if(this.item_info.tot_payment_mthd_purchase_price){
				t = this.item_info.tot_payment_mthd_purchase_price
			}

			return t
		}
		, message_01: function() {
			let t = 'NFT 컬랙션에서 구입하신<br>' + this.text_card_div_name + '를 확인하실 수 있습니다.'
			return t
		}
		, text_unit: function(){
			let t = ''
			switch (this.item_card.sl_leglter_div_code){
				case 'BC00800001':
					t = '₩'
					break;
				case 'BC00800002':
					t = '$'
					break;
				default:
					t = '₩'
					break;
			}
			return t
		}
	}
	, methods: {
		setLb: function () {
			if (this.lb == 'down') {
				this.lb = 'up'
			} else {
				this.lb = 'down'
			}
		}
	}

	, created() {
		console.log(this.item_result)
		let is_scroll = false
		setTimeout(() => {
			window.atvImg()

			let lt = 0
			let body = this.$refs.main_card_wrap
			body.addEventListener('scroll', () => {
				if (is_scroll) {
					return false
				} else {

					let st = body.scrollTop

					if (st > lt) {
						this.lb = 'up'
					} else {
						this.lb = 'down'
					}

					lt = st

					is_scroll = false
				}
			})
		}, 100)

	}
}
</script>

<style>
.down {
	bottom: 0;
	transition: all 1s
}

.up {
	bottom: -230px;
	transition: bottom 1s
}

.main_card_wrap::-webkit-scrollbar {
	width: 2px;

	background-color: #000;
}
.main_card_wrap::-webkit-scrollbar-thumb {
	background-color: #333;
}

.card-box {
	max-height: 880px;
	overflow-y: scroll;
	padding-bottom: 120px
}

.lb-wrap {
	position: fixed; left: 0; border: 1px solid rgba(255, 255, 255, 0.05); width: 100%; height: 264px; display: flex; flex-direction: row; justify-content: center; border-radius: 40px 40px 0 0; background-color: #181A39
}
.lb-trans {
	position: absolute; top: 14px; left: 50%; transform: translateX(-50%)
}
.lb-bg {
	width: 50px; height: 5px; background: #24264E; border-radius: 2.5px;
}
.lb-bottom-box {
	max-width: 320px; padding: 10px
}
</style>